// Entry point for the build script in your package.json

window.$ = require("jquery");
import Rails from "@rails/ujs";
Rails.start();

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-QZ9W5STPKJ');
